import React from 'react';
import SearchWidget from 'components/WidgetsList/Widgets/SearchWidget/SearchWidget';
import PropertyAutoSelectorWidget from 'components/WidgetsList/Widgets/PropertyAutoSelectorWidget/PropertyAutoSelectorWidget';
import FeaturedContentWidget from 'components/WidgetsList/Widgets/FeaturedContentWidget/FeaturedContentWidget';
import EmbedCodeWidget from 'components/WidgetsList/Widgets/EmbedCodeWidget/EmbedCodeWidget';
import TextBlockWidget from 'components/WidgetsList/Widgets/TextBlockWidget/TextBlockWidget';
import MasonaryWidget from 'components/WidgetsList/Widgets/MasonaryWidget/MasonaryWidget';
import LocationPickerWidget from 'components/WidgetsList/Widgets/LocationPickerWidget/LocationPicker';
import TestimonialsWidget from 'components/WidgetsList/Widgets/TestimonialsWidget/TestimonialsWidget';
import VideoWidget from 'components/WidgetsList/Widgets/VideoWidget/VideoWidget';
import FAQWidget from 'components/WidgetsList/Widgets/FAQWidget/FAQWidget';
import AnnoucementWidget from 'components/WidgetsList/Widgets/AnnoucementWidget/AnnoucementWidget';
import RandomPropertyWidget from 'components/WidgetsList/Widgets/RandomPropertyWidget/RandomPropertyWidget';
import GalleryWidget from 'components/WidgetsList/Widgets/GalleryWidget/GalleryWidget';
import PropertyPickerWidgets from 'components/WidgetsList/Widgets/PropertyPickerWidgets/PropertyPickerWidgets';


const WidgetsList = ({ Widgets, pageId, keyID, settings, googleMapAPIKey, currency }) => {
    const allWidgets = [];
    for (const widgetType in Widgets) {
        if (Widgets[widgetType]) {
            Widgets[widgetType].forEach(widget => {
                allWidgets.push({ ...widget, type: widgetType });
            });
        }
    }

    const validWidgets = allWidgets.filter(widget => widget !== null);
    validWidgets.sort((a, b) => a.SortOrder - b.SortOrder);

 
  
    return (
        <div>
            {validWidgets.map((widget, index) => {
                switch (widget.type) {
                    case 'SearchWidgets':
                        return <SearchWidget key={index} data={widget} pageId={pageId} keyID={keyID} settings={settings} />;
                    case 'PropertyAutoSelectorWidgets':
                        return <PropertyAutoSelectorWidget key={index} data={widget} googleMapAPIKey={googleMapAPIKey} currency={currency} searchFormSettings={settings} />;
                    case 'FeaturedContentWidgets':
                        return <FeaturedContentWidget key={index} data={widget} />;
                    case 'EmbedCodeWidgets':
                        return <EmbedCodeWidget key={index} data={widget} />;
                    case 'MasonaryWidgets':
                        return <MasonaryWidget key={index} data={widget} />;
                    case 'LocationPickerWidgets':
                        return <LocationPickerWidget key={index} data={widget} />;
                    case 'TestimonialsWidgets':
                        return <TestimonialsWidget key={index} data={widget} />;
                    case 'VideoWidgets':
                        return <VideoWidget key={index} data={widget} />;
                    case 'FAQWidgets':
                        return <FAQWidget key={index} data={widget} />;
                    case 'AnnoucementWidgets':
                        return <AnnoucementWidget key={index} data={widget} />;
                    case 'RandomPropertyWidgets':
                        return <RandomPropertyWidget key={index} data={widget} />;
                    case 'GalleryWidgets':
                        return <GalleryWidget key={index} data={widget} />;
                    case 'PropertyPickerWidgets':
                        return <PropertyPickerWidgets key={index} data={widget} />;   
                    case 'TextBlockWidgets':
                        return <TextBlockWidget key={index} data={widget} />; 
                    default:
                        return null;
                }
            })}
        </div>
    );
}

export default WidgetsList;
 