import React, { useEffect, useState, useRef } from 'react';
import './Location.css';
import useGetUrlParams from 'utils/useGetUrlParams';
import Select from 'multiselect-react-dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';

const Location = ({ changeLocation, locationList, enableLocationFilter, clearField, settings, pageID, locationsIds, limitLocations = false }) => {
  let locationRef = useRef();
  const [selectedOption, setSelectedOption] = useState();

  let [locationPlaceholder, setLocationPlaceholder] = useState(settings?.Dictionaries['Search.SearchLocationTitle'] || 'Locations');
  let [Location] = useGetUrlParams('Location');



  useEffect(() => {
    if (clearField > 0) {
      setSelectedOption([]);
      changeLocation('');
      setLocationPlaceholder("Locations");
    }
  }, [clearField]);

  useEffect(() => {
    if (pageID && locationList && locationsIds) {
      const selectedOptions = options.filter(option => locationsIds.includes(option.id))
        .map(option => ({ id: option.id, name: option.name }));

      setSelectedOption(selectedOptions);
    }
  }, [locationsIds]);

  useEffect(() => {
    
    if (pageID && locationList) {
      const optionMatchingPageID = options.find(option => option.id === pageID);

      if (optionMatchingPageID) {

        setSelectedOption([{ name: optionMatchingPageID.name, id: optionMatchingPageID.id }]);
        setTimeout(() => {
          changeLocation(pageID.toString());
        }, 1);
      }
    }
  }, [Location, pageID, locationList]);


  useEffect(() => {
    let urlParams = new URLSearchParams(window.location.search);
    let location = urlParams.get('location') || urlParams.get('Location');


    if (!Location && location) {
      Location = location;
    }


    if (Location === '') {
      //changeLocation('');
    }



    if (Location && locationList) {
      for (let i = 0; i < Location.length; ++i) {
        changeLocation(location);
      }

      const selectedIds = Location.split(',').filter(Boolean).map(Number);
      const selectedOptions = options.filter(option => selectedIds.includes(option.id))
        .map(option => ({ id: option.id, name: option.name }));
      setSelectedOption(selectedOptions);

      const isEmpty = selectedOptions.length === 0;
      const length = selectedOptions.length;
      if (!isEmpty) {
        setLocationPlaceholder(`Locations (${length})`);
      } else {
        setLocationPlaceholder(`Locations`);
      }
    }

    if (Location === 'All') {
      setLocationPlaceholder("Locations");
    }

  }, [Location, locationList]);

  // Dynamically adding a class to each `li` option after rendering
  useEffect(() => {
    // Make sure the location list exists and the DOM elements are rendered
    if (locationList) {
      const optionList = document.querySelectorAll('.optionContainer li');

      optionList.forEach((option, index) => {
        const locationItem = locationList[index]; // Get the corresponding item from locationList

        if (locationItem && locationItem.level) {
          option.classList.add(`level-${locationItem.level}`); // Add a class based on the level
        }
      });
    }
  }, [locationList]);


  let options = null;
  if (locationList && locationList !== '') {
    options = locationList
      .filter(({ level }) => !limitLocations || level === 3)
      .map(({ Id, Name }) => ({
        name: Name,
        id: Id,
        selected: true
      }));
  }



  function changeLocaationParameter(e) {

    if (e) {
      if (e.length === 0) setLocationPlaceholder(`Locations`);
      if (e.length > 0) {
        setLocationPlaceholder(`Locations (${e.length})`);
      }
      const ids = e.map(obj => obj.id).join(',');
      changeLocation(ids);

    }
  }



  return (
    <>

      {(enableLocationFilter && options) &&
        <div className="location-filter tw-border-t tw-border-b tw-border-[#ccc]">
          <span className='tw-absolute tw-top-3 tw-left-3'>
            <FontAwesomeIcon 
              icon={faLocationDot} 
              className='tw-text-primary'
            />
          </span>
          <div className="location-filter__select tw-ml-5">
            <Select
              ref={locationRef}
              options={options}
              showCheckbox={true}
              onSelect={changeLocaationParameter}
              onRemove={changeLocaationParameter}
              placeholder={locationPlaceholder}
              hidePlaceholder={false}
              hideSelectedList={true}
              displayValue="name"
              selectedValues={selectedOption}
              style={{
                multiselectContainer: {
                  color: 'black',
                  zIndex: '9999',
                },
                searchBox: {
                  borderRadius: '0px',
                  'height': '48px'
                }
              }}
            >

            </Select >
          </div>
        </div>
      }
    </>

  );
};

Location.propTypes = {};

Location.defaultProps = {};

export default Location;
