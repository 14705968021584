import React, { useEffect, useState, useContext } from 'react';
import { SEOContext, CurrencyContext } from '../../contexts/SiteSettingsProvider';
import WidgetsList from 'components/WidgetsList/WidgetsList';
import SkeletonFolder from './SkeletonFolder/SkeletonFolder';

const FolderPage = ({ pageId, keyID }) => {
    const { updateSeo } = useContext(SEOContext);
    const [currency, setCurrency] = useContext(CurrencyContext);
    const [widgets, setWidgets] = useState([]);
    const [response, setResponse] = useState(null);
    const [title, setTitle] = useState(null);
    const [content, setContent] = useState(null);
    const [settings, setSettings] = useState(null);
    const [subContentPages, setSubContentPages] = useState([]);

    const getContent = (sortDirection, Sort, Show, Page, clear) => {
        if (pageId) {
            fetch(`${process.env.REACT_APP_BASE_URL}apis/pagefolderpage?pageid=${pageId}&Currency=${currency}`)
                .then(response => response.json())
                .then(data => {
                    setResponse(true);
                    setWidgets(data.Page.Widgets);
                    setTitle(data.Page.PageH1Title);
                    setContent(data.Page.PageContent);
                    setSettings(data.Settings.SearchFormSettings);
                    setSubContentPages(data.Page.SubContentPages);
                    updateSeo(data.Settings.Seo);
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        }
    }

    useEffect(() => {
        getContent();
    }, [pageId, currency])

    if (!response) {
        return <SkeletonFolder />
    }

    function truncateHTML(html, limit) {
        const div = document.createElement('div');
        div.innerHTML = html;
        let textContent = div.textContent || div.innerText || '';

        if (textContent.length > limit) {
            textContent = textContent.substring(0, limit) + '...';
        }

        return textContent;
    }

    return (
        <>
            <div>
                <div className="tw-container tw-m-auto">
                    {title && <h1>{title}</h1>}

                    {content && <div dangerouslySetInnerHTML={{ __html: content }}></div>}

                    {subContentPages &&
                        <div className="tw-mt-3 tw-grid sm:tw-grid-cols-1 md:tw-grid-cols-2 2xl:tw-grid-cols-4 tw-gap-4 tw-flex tw-items-stretch">
                            {subContentPages.map((page, index) => (
                                <div className="tw-flex tw-flex-col tw-relative page-folder-item" key={index}>
                                    {(page.Preview && page.Preview.length > 0) ? (
                                        <>
                                            <a href={page.Url} className="image-container tw-flex-grow tw-relative page-folder-item-image-container tw-overflow-hidden">
                                                {page.Image ? (
                                                    <img
                                                        src={page.Image.Url.startsWith('/') ? page.Image.Url : `/${page.Image.Url}`}
                                                        alt={page.Name}
                                                        className="tw-w-full tw-h-[200px] tw-object-cover image"
                                                    />
                                                ) : (
                                                    <div className="tw-h-[200px] tw-w-full tw-bg-primary"></div>
                                                )}
                                            </a>
                                            <div className='tw-p-5 tw-flex tw-flex-col tw-justify-between tw-h-[150px]'>
                                                {page.Title && (
                                                    <h4 className="tw-tex-lg">
                                                        <a href={page.Url} className="tw-text-primary ">{page.Title}</a>
                                                    </h4>
                                                )}
                                                <div
                                                    className="tw-text-sm tw-mt-2 tw-flex-grow tw-overflow-hidden"
                                                    dangerouslySetInnerHTML={{
                                                        __html: truncateHTML(page.Preview, 120)
                                                    }}
                                                />
                                            </div>
                                        </>

                                    ) : (
                                        <a href={page.Url} className="tw-no-underline tw-flex tw-flex-col tw-relative page-folder-item" key={index}>
                                            <div className="image-container tw-flex-grow tw-relative page-folder-item-image-container">

                                                {page.Title && (
                                                    <div className="tw-absolute tw-inset-0 tw-flex tw-justify-center tw-items-center page-folder-item-title">
                                                        <h4 className="image-title tw-text-white tw-shadow-md tw-px-2 tw-py-1 tw-h-full tw-flex tw-justify-center tw-items-center">
                                                            {page.Title}
                                                        </h4>
                                                    </div>
                                                )}
                                                    {page.Image ? (
                                                        <img
                                                            src={page.Image.Url.startsWith('/') ? page.Image.Url : `/${page.Image.Url}`}
                                                            alt={page.Name}
                                                            className="tw-w-full tw-h-full tw-object-cover tw-min-h-[250px] image"
                                                        />
                                                    ) : (
                                                        <div className="tw-h-full tw-w-full tw-min-h-[200px] tw-bg-primary"></div>
                                                    )}
                                            </div>
                                        </a>
                                    )}
                                </div>
                            ))}
                        </div>
                    }


                </div>
            </div>

            <WidgetsList Widgets={widgets} pageId={pageId} keyID={keyID} settings={settings} />
        </>
    );
};

export default FolderPage;
